import {Component, Input} from "@angular/core";
import { Report } from "@rezonence/core";

@Component({
  selector: "app-top-bar",
  templateUrl: "./TopBar.html",
  styleUrls: [
    "./TopBar.css"
  ]
})
export class TopBar {

  @Input()
  public report: Report;

}
