import {
  Component,
  ElementRef, Input, OnInit,
  ViewEncapsulation
} from '@angular/core'
import Hammer from 'hammerjs'
import { Report, AnswerData } from '@rezonence/core'
import { AnswerCountRecord } from "@rezonence/analytics-dao";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-freewall-banners',
  templateUrl: './FreeWallBanners.html',
  styleUrls: ['./FreeWallBanners.css']
})
export class FreeWallBanners implements OnInit {

  @Input()
  report: Report;

  @Input()
  answerCounts: AnswerCountRecord[];

  public hammer;
  public interval: number = 3000;

  constructor(private element: ElementRef) {
  }

  public ngOnInit(): void {
    this.hammer = new Hammer(this.element.nativeElement)
    this.hammer.on('panleft panright tap press', () => {
      this.interval = 0
    })
  }

}
