<ng-container *ngIf="eventCounts$ | async as eventCounts">
  <ng-container *ngIf="timeZone$ | async as timeZone">
    <div [style.margin-bottom]="'15px'">
        <button mat-button mat-flat-button appDownloadButton [report]="report" [timeZone]="timeZone"
        [counts]="eventCounts" color="primary">Download</button>
      <button mat-button mat-flat-button *ngIf="eventCounts | sum:EventType.VideoStarted" (click)="toggleVideoColumns()"
        class="pull-right" color="primary">
        <ng-container *ngIf="showVideoColumns; else standardBlock">
          <ng-container *ngIf="report.displayClicks; else engagementBlock">
            Show clicks
          </ng-container>
          <ng-template #engagementBlock>
            Engagement data
          </ng-template>
        </ng-container>
        <ng-template #standardBlock>
          Show video data
        </ng-template>
      </button>
    </div>
    <ng-container *ngIf="eventCounts | selectColumns:report:showVideoColumns as displayedColumns">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="Date" matSortDirection="desc" matSortDisableClear>
        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column}}</th>
          <td mat-cell *matCellDef="let entry">{{entry[column] | displayValue : column : timeZone}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator #paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons>
      </mat-paginator>
    </ng-container>
  </ng-container>
</ng-container>
