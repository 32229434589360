import { emptyTrackingConfig } from "@rezonence/freewall-loader";
import { customTrackersVar } from "@rezonence/core";
import { type SingleTrackerType } from "@rezonence/sdk";
import { type TrackingConfig } from "@rezonence/freewall-tracking";

export function createTrackingConfig(): TrackingConfig {
  const customTrackers = (window as Window & typeof globalThis & { [customTrackersVar]: Partial<Record<SingleTrackerType, string[]>> | undefined }).CUSTOM_TRACKERS || {};
  const trackingConfig = { ...emptyTrackingConfig(), ...customTrackers };
  // This string is replaced with the default click macro of the ad server used, make sure that it's the first tracker to be fired on engagement
  trackingConfig.eng.unshift("[YOUR_ENGAGEMENT_TRACKER]");
  return trackingConfig;
}
