export enum Column {
    Date = "Date",
    TimeZone = "Time zone",
    ViewableImpressions = "Viewable impressions",
    Engagements = "Engagements",
    EngagementRate = "Engagement rate",
    Clicks = "Clicks",
    ClickThroughRate = "CTR",
    VideoStarts = "Video starts",
    VideoCompletions = "Video completions",
    VideoCompletionRate = "VCR",
    VideoStartRate = "VSR"
}