import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "toTimestamp"
})
export class TimestampPipe implements PipeTransform {
    transform(value: string | number | Date): number {
        return new Date(value).getTime();
    }

}