import { Component, Input } from "@angular/core";
import { AnswerCountRecord } from "@rezonence/analytics-dao";
import { ButtonSelector } from "@rezonence/freewall-buttons";

@Component({
  selector: "answer-share",
  template: `
    <ng-container *ngIf="answerCounts | adIdCounts : adId as adIdAnswerCounts">
        <ng-container *ngFor="let countRecord of adIdAnswerCounts">
        <ng-container *ngIf="button.getBoundingClientRect() as rect">
        <div *ngIf="countRecord.aid === adId && countRecord.qnm === questionIndex && countRecord.ans === answerIndex" [style.position]="'absolute'" [style.top]="rect.top - (radius)" [style.left]="rect.right - (radius * 1.5)"
            >
                <circle-progress
                    [percent]="100.0 * countRecord.counts / (adIdAnswerCounts | sum: 'counts')"
                    [radius]="radius"
                    [maxPercent]="100"
                    [outerStrokeLinecap]="'square'"
                    [space]="0"
                    [titleColor]="'black'"
                    [backgroundStrokeWidth]="1"
                    [backgroundStroke]="'lightgrey'"
                    [outerStrokeWidth]="3"
                    [backgroundPadding]="0"
                    [showSubtitle]="false"
                    [unitsFontSize]="'10'"
                    [titleFontSize]="'10'"
                    [showInnerStroke]="false"
                    [showBackground]="true"
                    [backgroundColor]="'#d1f0ff'"
                    [outerStrokeColor]="'#40caa8'"
                    [animation]="true"
                    [animationDuration]="300"
                ></circle-progress>
            </div>
        </ng-container>

        </ng-container>
    </ng-container>
`
})
export class AnswerShare {

  @Input()
  adId: string;

  @Input()
  questionIndex: number;

  @Input()
  button: HTMLDivElement;

  @Input()
  answerCounts: AnswerCountRecord[];

  radius = 14;

  get classList(): string[] {
    return Array.from(this.button.classList);
  }

  get answerIndex(): number | undefined {
    const answerPrefix = `${ButtonSelector.Answer}-`;
    return this.classList.filter(c => c.startsWith(answerPrefix))
      .map(indexClass => parseInt(indexClass.replace(answerPrefix, "")) - 1)
      .shift();
  }

}
