import { ReportSource } from "./ReportSource";
import { Report } from "@rezonence/core";
import { Optional } from "@rezonence/sdk";
import { ReportRequest } from "./ReportRequest";
import { AnalyticsClient } from "./AnalyticsClient";

export class ReportSourceClient extends AnalyticsClient<ReportRequest, Report> implements ReportSource {

  constructor(endpoint: URL) {
    super(endpoint);
  }

  find(request: ReportRequest): Promise<Optional<Report>> {
    return this.request(request);
  }

}
