import { Component, ViewEncapsulation } from "@angular/core";
import { Report } from "@rezonence/core";
import { EventCountResolver, ReportConfig, ReportConfigResolver, ReportResolver, TimeZoneService, AnswerCountResolver } from "./data";
import { Observable } from "rxjs";
import { AnswerCountRecord, EventCountRecord } from "@rezonence/analytics-dao";
import { FreewallEventColumn } from "@rezonence/freewall-events";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-root",
  templateUrl: "./App.html",
  styleUrls: ["./App.css"]
})
export class App {
  readonly report$: Observable<Report>;
  readonly counts$: Observable<EventCountRecord<FreewallEventColumn>[]>;
  readonly timeZone$: Observable<string>;
  readonly reportConfig$: Observable<ReportConfig>;
  readonly answerCounts$: Observable<AnswerCountRecord[]>;
  // eslint-disable-next-line max-params
  constructor(eventCountResolver: EventCountResolver,
    reportResolver: ReportResolver,
    timeZoneService: TimeZoneService,
    reportConfigResolver: ReportConfigResolver,
    answerCountResolver: AnswerCountResolver) {
    this.timeZone$ = timeZoneService.timeZone$;
    this.report$ = reportResolver.report$;
    this.counts$ = eventCountResolver.rows$;
    this.reportConfig$ = reportConfigResolver.reportConfig$;
    this.answerCounts$ = answerCountResolver.rows$;
  }

}
