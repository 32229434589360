import { Component, ComponentRef, Input, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { Report } from "@rezonence/core";
import { combineLatest, map, Observable, ReplaySubject, Subject, Subscription } from "rxjs";
import { DemoBanner } from "./DemoBanner";
import { AnswerCountRecord } from "@rezonence/analytics-dao";


@Component({
  selector: "embedded",
  template: `
        <iframe #iframe (load)="onLoad(iframe)" [style.height.px]="(iframe$ | async).contentDocument.body.offsetHeight" scrolling="no">
        </iframe>
    `,
  styles: [
    `
        iframe {
            border: 0;
            padding: 0;
            width: 100%;
        }
        `
  ]
})
export class Embedded implements OnInit, OnDestroy {
  adId$: Subject<string> = new ReplaySubject(1);
  questionIndex$: Subject<number> = new ReplaySubject(1);
  iframe$: Subject<HTMLIFrameElement> = new ReplaySubject(1);
  answerCounts$: Subject<AnswerCountRecord[]> = new ReplaySubject(1);
  report$: Subject<Report> = new ReplaySubject(1);
  componentRef$: Observable<ComponentRef<DemoBanner>> = combineLatest([
    this.adId$,
    this.questionIndex$,
    this.answerCounts$,
    this.report$,
    this.iframe$
  ]).pipe(map(([
    adId,
    questionIndex,
    answerCounts,
    report,
    iframe
  ]) => this.createComponentRef(adId, questionIndex, answerCounts, report, iframe.contentWindow)));

  subscription: Subscription;

  constructor(private vcRef: ViewContainerRef) {
  }

  @Input()
  set report(report: Report) {
    this.report$.next(report);
  }

  @Input()
  set answerCounts(input: AnswerCountRecord[]) {
    this.answerCounts$.next(input);
  }

  @Input()
  set questionIndex(index: number) {
    this.questionIndex$.next(index);
  }

  @Input()
  set adId(input: string) {
    this.adId$.next(input);
  }

  onLoad(iframe: HTMLIFrameElement): void {
    const url = `about:blank${window.location.search}`;
    if (iframe.contentWindow.location.href !== url) {
      iframe.contentWindow.location.href = url;
    }
    this.iframe$.next(iframe);
  }

  createComponentRef(adId: string, questionIndex: number, answerCounts: AnswerCountRecord[], report: Report, context: Window): ComponentRef<DemoBanner> {
    const componentRef = this.vcRef.createComponent(DemoBanner);
    componentRef.instance.context = context;
    componentRef.instance.adId = adId;
    componentRef.instance.questionIndex = questionIndex;
    componentRef.instance.answerCounts = answerCounts;
    componentRef.instance.report = report;
    return componentRef;
  }

  async insertComponent(componentRef: ComponentRef<DemoBanner>, iframe: HTMLIFrameElement, questionIndex: number): Promise<void> {
    iframe.contentDocument.body.appendChild(componentRef.location.nativeElement);
    const component = componentRef.instance;
    const loadedQuestionIndex = await component.resolveLoadedQuestionIndex();
    if (loadedQuestionIndex !== questionIndex) {
      iframe.contentWindow.location.reload();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = combineLatest([this.componentRef$, this.iframe$, this.questionIndex$]).subscribe(([componentRef, iframe, questionIndex]) => this.insertComponent(componentRef, iframe, questionIndex))
  }
}
