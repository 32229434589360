import {Directive, HostListener, Input} from "@angular/core";
import {Report} from "@rezonence/core";
import {EventCountRecord} from "@rezonence/analytics-dao";
import {ReportDataDownloader} from "./ReportDataDownloader";
import { FreewallEventColumn } from "@rezonence/freewall-events";

@Directive({
  selector: "[appDownloadButton]"
})
export class DownloadButton {

  @Input()
  report: Report;

  @Input()
  counts: EventCountRecord<FreewallEventColumn>[];

  @Input()
  timeZone: string;

  constructor(private downloader: ReportDataDownloader) {
  }

  @HostListener("click")
  public downloadReport() {
    this.downloader.download({
      report: this.report,
      rows: this.counts,
      timeZone: this.timeZone
    });
  }

}
