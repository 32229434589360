import { TemplateFileSuffix, CDN } from "@rezonence/core";
import { FreeWallConfig, FreewallFilenamePrefix } from "@rezonence/sdk";
import { FetchAdConfigRequest } from "./fetch.ad.config.request";
import { toAdFileUrl } from "./to.ad.file.url";


export function fetchAdConfig(request: FetchAdConfigRequest): Promise<{ cdn: CDN, config: FreeWallConfig }> {
    const fileName = `${FreewallFilenamePrefix.Ad}${TemplateFileSuffix.Conf}`;
    const requests = request.cdns.map(async cdn => ({
        cdn,
        config: await (await fetch(toAdFileUrl({ cdn, fileName, adId: request.adId }))).json()
    }));
    return Promise.any(requests);
}
