import { ReportDataQueryType, ReportDataValueTypes, ReportDataClient, resolveDefaultDateRangeOptions } from "@rezonence/analytics-dao";
import { Config } from "@rezonence/live-report-config";
import { Observable, concatMap, filter, map, combineLatest } from "rxjs";
import { ReportConfig } from "./ReportConfig";
import { Grouping } from "@rezonence/date-range";
import { FreewallEventColumn } from "@rezonence/freewall-events";

export class ReportDataResolver<K extends ReportDataQueryType> {

  public readonly rows$: Observable<ReportDataValueTypes<FreewallEventColumn>[K][]>;

  public readonly groupingByReportType: Record<ReportDataQueryType, Grouping> = {
    [ReportDataQueryType.AnswerCounts]: Grouping.None,
    [ReportDataQueryType.EventCounts]: Grouping.Date
  };

  protected readonly client: ReportDataClient<K, FreewallEventColumn>;

  constructor(reportType: K, config: Config, params: { timeZone: Observable<string>, reportConfig: Observable<ReportConfig> }) {
    this.client = new ReportDataClient(new URL(config.reportDataEndpoint), { ...resolveDefaultDateRangeOptions(), groupBy: this.groupingByReportType[reportType] });
    this.rows$ = combineLatest([params.reportConfig, params.timeZone])
      .pipe(map(([reportConfig, timeZone]) => ({ reportId: reportConfig.reportId, timeZone })))
      .pipe(concatMap(input => this.client.request({ ...input, reportType })))
      .pipe(filter(response => response.exists))
      .pipe(map(response => response.item));
  }
}
