<ng-container *ngIf="(report$ | async) && (counts$ | async) && (answerCounts$ | async); else loading">
  <ng-container *ngIf="report$ | async as report">
    <ng-container *ngIf="counts$ | async as counts">
      <app-top-bar [report]="report"></app-top-bar>
      <ng-container *ngIf="timeZone$ | async as timeZone">
        <div class="report-header">
          <div *ngIf="report.targetEngagements" class="progress-container">
            <div>
              <p>Progress</p>
            </div>
            <app-report-progress [report]="report" [counts]="counts"></app-report-progress>
            <div>
              <p>Time</p>
            </div>
          </div>
          <div class="jumbotron">
            <div class="container-fluid">
              <div class="row report-desc">
                <div class="col-md-6">
                  <app-report-summary [report]="report" [reportConfig]="reportConfig$ | async" [rows]="counts">
                  </app-report-summary>
                  <app-report-data [report]="report" [timeZone]="timeZone" [eventCounts]="counts"></app-report-data>
                </div>
                <div class="col-md-6">
                  <app-freewall-banners [report]="report" [answerCounts]="answerCounts$ | async">
                  </app-freewall-banners>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loading>
  <div class="loading">
    <mat-progress-spinner mode="indeterminate" color="accent"></mat-progress-spinner>
  </div>
</ng-template>
