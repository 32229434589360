import { TrackingConfig } from "@rezonence/freewall-tracking";
import { SingleTrackerType, AnswerTrackerType } from "@rezonence/sdk";

function emptyArrays<T extends string | number>(keys: T[]): Record<T, []> {
    return keys.reduce((output, key) => ({ ...output, [key]: [] }), {} as Record<T, []>);
}

export function emptyTrackingConfig(): TrackingConfig {
    return {
        ...emptyArrays(Object.values(SingleTrackerType)),
        ...emptyArrays(Object.values(AnswerTrackerType)),
        ctu: ""
    }
};