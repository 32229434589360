import { Pipe, PipeTransform } from "@angular/core";
import { Report } from "@rezonence/core";

@Pipe({
    name: "timeProgress"
})
export class TimeProgressPipe implements PipeTransform {
    transform(report: Report, at: Date): number {
        const duration = new Date(report.endDate).getTime() - new Date(report.startDate).getTime();
        return (at.getTime() - new Date(report.startDate).getTime()) / duration;
    }

}