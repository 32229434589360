import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/AppModule';
import { environment } from './environments/environment';
import { resolveSiteConfig } from "@rezonence/site-config";
import { Config } from "@rezonence/live-report-config";
import { InfrastructureResolver } from "./app/data";

if (environment.production) {
  enableProdMode();
}

resolveSiteConfig<Config>().then(config => {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic([
    { provide: InfrastructureResolver, useValue: new InfrastructureResolver(config) },
  ]).bootstrapModule(AppModule);
})
