<div *ngIf="rows | sum:EventType.InView as views">
  <div class="report-summary">
    <form class="form-horizontal">
      <div class="references" *ngIf="report.externalReference && report.internalReference">
        <div class="form-group">
          <label class="col-sm-6 control-label">Your reference:</label>
          <div class="col-sm-6">
            <p class="form-control">{{report.externalReference}}</p>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-6 control-label">Our reference:</label>
          <div class="col-sm-6">
            <p class="form-control">{{report.internalReference}}</p>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="col-sm-6 control-label">Campaign dates:</label>
        <div class="col-sm-6">
          <p class="form-control">{{report.startDate | date:dateFormat}} - {{report.endDate | date:dateFormat}}</p>
        </div>
      </div>
      <div *ngIf="reportConfig.showImpressions">
        <div class="form-group">
          <label class="col-sm-6 control-label">Viewable impressions:</label>
          <div class="col-sm-6">
            <p class="form-control">{{views | number}}
              <app-help-button (helpClick)="displayInfo(HelpTopic.Impression)"></app-help-button>
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="report.targetEngagements" class="form-group">
        <label class="col-sm-6 control-label">Engagements booked:</label>
        <div class="col-sm-6">
          <p class="form-control">{{report.targetEngagements | number}}
            <app-help-button (helpClick)="displayInfo(HelpTopic.EngagementRate)"></app-help-button>
          </p>
        </div>
      </div>
      <ng-container *ngIf="rows | sum:EventType.FreeWallUnlocked as engagements">
        <div class="form-group">
          <label class="col-sm-6 control-label">Engagements delivered:</label>
          <div class="col-sm-6">
            <p class="form-control">
              {{engagements | number}} ({{engagements / views | percent:decimalFormat}} ER)
              <app-help-button (helpClick)="displayInfo(HelpTopic.Engagement)"></app-help-button>
            </p>
          </div>
        </div>
        <!-- <ng-container *ngIf="report.endDate | daysRemaining as remainingDays">
          <ng-container *ngIf="report.targetEngagements - engagements as remainingEngagements">
            <ng-container *ngIf="remainingDays > 0 && remainingEngagements > 0">
              <div class="form-group">
                <label class="col-sm-6 control-label">Engagements required:</label>
                <div class="col-sm-6">
                  <p class="form-control">
                    <ng-container *ngIf="remainingDays > 2; else elseBlock">
                      {{remainingEngagements / remainingDays | number : '1.0-0'}} per day
                    </ng-container>
                    <ng-template #elseBlock>
                      {{remainingEngagements | number}}
                    </ng-template>
                  </p>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container> -->
        <ng-container
          *ngIf="rows | sum:EventType.BannerClicked:EventType.FreeWallClicked:EventType.IframeClicked:EventType.ResponseClicked:EventType.KickerClicked as clicks">
          <div *ngIf="report.displayClicks" class="form-group">
            <label class="col-sm-6 control-label">Clicks:</label>
            <div class="col-sm-6">
              <p class="form-control">{{clicks | number}} ({{clicks / engagements | percent:decimalFormat}} CTR)
                <app-help-button (helpClick)="displayInfo(HelpTopic.Click)"></app-help-button>
              </p>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="rows | sum:EventType.VideoStarted as videoStarts">
        <!-- <div *ngIf="videoStarts > 0" class="form-group">
          <label class="col-sm-6 control-label">Video starts:</label>
          <div class="col-sm-6">
            <p class="col-sm-6 form-control">
              {{videoStarts | number}}
              ({{videoStarts/views | percent:decimalFormat}} VSR)
              <app-help-button (helpClick)="displayInfo(HelpTopic.VideoStart)"></app-help-button>
            </p>
          </div>
        </div> -->
        <div *ngIf="videoStarts > 0" class="form-group">
          <label class="col-sm-6 control-label">Video completions:</label>
          <div class="col-sm-6">
            <p class="form-control">{{rows | sum:EventType.VideoCompleted | number}}
              ({{(rows | sum:EventType.VideoCompleted ) / videoStarts | percent:decimalFormat}} VCR)
              <app-help-button (helpClick)="displayInfo(HelpTopic.VideoCompletion)"></app-help-button>
            </p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="rows | sum:EventType.FreeWallUnlocked as engagements">
        <ng-container *ngIf="report.displayECPM">
          <div class="form-group">
            <label class="col-sm-6 control-label">eCPM:</label>
            <div class="col-sm-6">
              <p class="form-control">{{1000 * report.cpe * engagements / views | currency: report.cpeCurrency
                :true:decimalFormat}} ({{report.cpe | currency: report.cpeCurrency
                :true:decimalFormat }} CPE)
                <app-help-button (helpClick)="displayInfo(HelpTopic.Cost)"></app-help-button>
              </p>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>
</div>
