import { CustomAttribute } from "./CustomAttribute";
import { Optional } from "@rezonence/sdk";
import { Attributes } from "./Attributes";
import { Entries, ValueOf } from "type-fest";

export class AttributeStore<T extends object, H extends HTMLElement = HTMLElement> {

  constructor(private element: H) {
  }

  get<K extends keyof T & string>(attribute: CustomAttribute<K>): Optional<T[K]> {
    return this.getStringValue<K>(attribute).map(v => JSON.parse(v) as T[K]);
  }

  getStringValue<K extends keyof T & string>(attribute: CustomAttribute<K>): Optional<string> {
    return Optional.of(this.element.getAttribute(attribute));
  }

  set<K extends keyof T & string>(attribute: CustomAttribute<K>, value: T[K]): void {
    this.element.setAttribute(attribute, JSON.stringify(value));
  }

  apply(attributes: Attributes<T>): H {
    for (const [key, value] of (Object.entries(attributes) as Entries<Attributes<T>>)) {
      this.set(key as CustomAttribute<keyof T & string>, value as ValueOf<T, keyof T & string>);
    }
    return this.element;
  }

}
