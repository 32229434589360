import {Component, Input, ViewEncapsulation, OnInit} from "@angular/core";
import { Report} from "@rezonence/core";
import {TickDisplayType} from "./TickDisplayType";
import {AxisOrientation} from "./AxisOrientation";
import { EventCountRecord } from "@rezonence/analytics-dao";
import { EventType } from "@rezonence/sdk";
import { FreewallEventColumn } from "@rezonence/freewall-events";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-report-progress",
  templateUrl: "./ReportProgress.html",
  styleUrls: ["./ReportProgress.css"]
})
export class ReportProgress {

  @Input()
  report: Report;

  @Input()
  counts: EventCountRecord<FreewallEventColumn>[];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  TickDisplayType = TickDisplayType;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  AxisOrientation = AxisOrientation;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  EventType = EventType;

}
