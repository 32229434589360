import { PipeTransform, Pipe } from "@angular/core";
import { AnswerCountRecord } from "@rezonence/analytics-dao";

@Pipe({
  name: "adIdCounts"
})
export class AnswerCountsPipe implements PipeTransform {

  transform(answerCounts: AnswerCountRecord[], adId: string): AnswerCountRecord[] {
    return answerCounts.filter(a => a.aid === adId);
  }

}
