<div class="freewall-banners" *ngIf="answerCounts && report">
  <carousel [interval]="interval" [isAnimated]="true" [noWrap]="false">
    <ng-container *ngFor="let adId of report | adIds : answerCounts">
      <ng-container *ngIf="adId | freewallConfig | async as optionalConfig">
        <ng-container *ngIf="optionalConfig.item as config">
          <ng-container *ngFor="let question of config.questions; let questionIndex = index">
            <slide>
              <label class="control-label text-center">
                <a href="{{adId | demoLink}}" target="_blank">
                  {{adId | toTitle}}
                  <span><a class="glyphicon glyphicon-new-window" href="{{adId | demoLink}}" target="_blank"></a></span>
                </a>
              </label>
              <embedded [adId]="adId" [questionIndex]="questionIndex" [answerCounts]="answerCounts" [report]="report">
              </embedded>
            </slide>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </carousel>
</div>
