import { Injectable } from "@angular/core";
import { ReportDataQueryType } from "@rezonence/analytics-dao";
import { ReportDataResolver } from "./ReportDataResolver";
import { ReportConfigResolver } from "./ReportConfigResolver";
import { InfrastructureResolver } from "./InfrastructureResolver";
import { TimeZoneService } from "./TimeZoneService";

@Injectable({
    providedIn: "root"
})
export class AnswerCountResolver extends ReportDataResolver<ReportDataQueryType.AnswerCounts> {
    constructor(reportConfigResolver: ReportConfigResolver, infra: InfrastructureResolver, timeZoneService: TimeZoneService) {
        super(ReportDataQueryType.AnswerCounts, infra.config, { reportConfig: reportConfigResolver.reportConfig$, timeZone: timeZoneService.timeZone$ });
    }
}
