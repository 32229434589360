import { NgModule } from "@angular/core";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { FreeWallBanners } from "./FreeWallBanners";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { DataModule } from "../data";
import { NvD3Module } from "@rezonence/ng2-nvd3";
import { ActiveListener } from "./ActiveListener";
import { TitlePipe } from "./TitlePipe";
import { FreeWallConfigPipe } from "./FreeWallConfigPipe";
import { DemoLinkPipe } from "./DemoLinkPipe";
import { DemoBanner } from "./DemoBanner";
import { Embedded } from "./Embedded";
import { AdIdsPipe } from "./AdIdsPipe";
import { AnswerShare } from "./AnswerShare";
import { AnswerCountsPipe } from "./AnswerCountsPipe";
import { NgCircleProgressModule } from "ng-circle-progress";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    CarouselModule.forRoot(),
    DataModule,
    NvD3Module,
    NgCircleProgressModule.forRoot()
  ],
  declarations: [
    FreeWallConfigPipe,
    FreeWallBanners,
    ActiveListener,
    TitlePipe,
    DemoLinkPipe,
    DemoBanner,
    Embedded,
    AdIdsPipe,
    AnswerShare,
    AnswerCountsPipe,
  ],
  exports: [
    FreeWallBanners
  ]

})
export class BannerModule {

}
