import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { SumPipe } from "./SumPipe";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forChild([]),
    HttpClientModule
  ],
  declarations: [
    SumPipe
  ],
  exports: [
    SumPipe,
  ]
})
export class DataModule {

}
