import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "toDate"
})
export class ToDatePipe implements PipeTransform {
    transform(input: string | number | Date): Date {
        return new Date(input);
    }

}