import { Injectable } from "@angular/core";
import { CDN } from "@rezonence/core";
import { ConfigResolver, LinkMacroRenderer, ConfigDownloader, FreeWallConfig, Optional } from "@rezonence/sdk";

@Injectable({
  providedIn: "root"
})
export class ConfigResolverService extends ConfigResolver {

  constructor() {
    super(new ConfigDownloader(), new LinkMacroRenderer)
  }

  fromAdId(adId: string): Promise<Optional<FreeWallConfig>> {
    return this.resolveFreeWallConfig({
      adId,
      cdn: new URL(CDN.CDN3)
    })
  }

}
