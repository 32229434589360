import { Injectable } from "@angular/core";
import { ReportSourceClient } from "@rezonence/analytics-dao";
import { Report } from "@rezonence/core";
import { Observable } from "rxjs";
import { concatMap, filter, map } from 'rxjs/operators';
import { InfrastructureResolver } from "./InfrastructureResolver";
import { ReportConfigResolver } from "./ReportConfigResolver";

@Injectable({
    providedIn: "root"
})
export class ReportResolver {

    public readonly report$: Observable<Report>;
    protected client: ReportSourceClient;

    constructor(infra: InfrastructureResolver, configResolver: ReportConfigResolver) {
        this.client = new ReportSourceClient(new URL(infra.config.reportResolverEndpoint));
        this.report$ = configResolver.reportConfig$
        .pipe(concatMap(config => this.client.find({reportId: config.reportId})))
        .pipe(filter(v => v.exists))
        .pipe(map(v => v.item))
    }
}
