import { BehaviorSubject, Observable } from "rxjs";
import { resolveCurrentTimeZone } from "@rezonence/analytics-dao";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class TimeZoneService {
    timeZone$: Observable<string> = new BehaviorSubject<string>(resolveCurrentTimeZone());
}
