import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "currentDate"
})
export class CurrentDatePipe implements PipeTransform {
    transform(_value: any): Date {
       return new Date();
    }

}