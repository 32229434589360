import {Component, Output, EventEmitter} from "@angular/core";
@Component({
  selector: "app-help-button",
  template: `<span><a [style.cursor]="'pointer'" class="glyphicon glyphicon-question-sign" (click)="onClick($event)"></a></span>`
})
export class HelpButton {

  @Output()
  public helpClick: EventEmitter<any> = new EventEmitter();

  public onClick(event) {
    this.helpClick.emit(event);
  }

}
