import { Pipe, PipeTransform } from "@angular/core";
import { Duration } from "@rezonence/duration";

@Pipe({
    name: "daysRemaining"
})
export class DaysRemainingPipe implements PipeTransform {
    
    transform(date: Date | string | number, inteval = Duration.MsInDay): number {
        return (new Date(date).getTime() - new Date().getTime())/inteval;
    }

}