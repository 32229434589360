import { createTrackingConfig } from "./createTrackingConfig";
import { loaderFilename, TagConfig, xandrMacroValue, LoaderConfig, AttributeStore } from "@rezonence/freewall-loader";
import { CDN, TemplateFolderResolver, CompileFolder } from "@rezonence/core";
import { isRestrictedContext } from "@rezonence/sdk";
import { createScriptElement } from "@rezonence/freewall-tracking";
import { fetchAdConfig } from "./fetch.ad.config";

export async function race(cdns: CDN[], tag: TagConfig, context = isRestrictedContext() || !tag.top ? window : top): Promise<HTMLScriptElement> {
  const { cdn, config } = await fetchAdConfig({
    cdns,
    adId: tag.aid,
  });
  const templateResolver = new TemplateFolderResolver();
  const baseUrl = templateResolver.toUrl({
    compileFolder: CompileFolder.FullFlex,
    version: config.version
  }, cdn);
  const loaderUrl = `${baseUrl.toString()}/${loaderFilename}`;
  const attributeStore = new AttributeStore<LoaderConfig, HTMLScriptElement>(createScriptElement(loaderUrl, context));
  const scriptElement = attributeStore.apply({
    "data-tag-config": tag,
    "data-tracking-config": createTrackingConfig(),
    "data-macros": xandrMacroValue
  });
  const head = context.document.head;
  head.insertBefore(scriptElement, head.firstChild);
  return scriptElement;
}
