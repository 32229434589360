// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  reportServiceLambdaName: "report-prod-data",
  devReportServiceLambdaName: "report-dev-data",
  reportIdQueryParamKey: "c",
  identityPoolId: "eu-west-1:f6dcd774-9c2c-45a8-aa3c-d68494b79b8a"
};
/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
