import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "toTitle"
})
export class TitlePipe implements PipeTransform {
    transform(adId: string): string {
        return `Creative: ${adId}`;
    }

}