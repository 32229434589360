import { Pipe, PipeTransform } from "@angular/core";
import { toDemoUrl } from "@rezonence/freewall-demo-client";

@Pipe({
  name: "demoLink"
})
export class DemoLinkPipe implements PipeTransform {

  transform(adId: string): string {
    const url = toDemoUrl({
      aid: adId
    });
    return url.toString();
  }

}
