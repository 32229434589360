import { NgModule } from "@angular/core";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { ProgressBarAxis } from "./ProgressBarAxis";
import { ReportProgress } from "./ReportProgress";
import { DataModule } from "../data";
import { TimestampPipe } from "./TimestampPipe";
import { CurrentDatePipe } from "./CurrentDatePipe";
import { ToDatePipe } from "./ToDatePipe";
import { TimeProgressPipe } from "./TimeProgressPipe";
import { DelayPipe } from "./DelayPipe";

@NgModule({
  imports: [
    ProgressbarModule.forRoot(),
    BrowserModule,
    FormsModule,
    DataModule
  ],
  declarations: [
    ToDatePipe,
    ProgressBarAxis,
    ReportProgress,
    TimestampPipe,
    TimeProgressPipe,
    CurrentDatePipe,
    DelayPipe
  ],
  exports: [
    ProgressBarAxis,
    ReportProgress,
    DelayPipe
  ]
})
export class ChartModule {

}
