import { Pipe, PipeTransform } from "@angular/core";
import { EventCountRow, ReportDataFormatter } from "../data";

@Pipe({
    name: "displayValue"
})
export class DisplayValuePipe implements PipeTransform {
    constructor(private dataFormatter: ReportDataFormatter) {
    }

  transform<K extends keyof EventCountRow>(value: EventCountRow[K], key: K, timeZone: string): string {
        return this.dataFormatter.formatters[key].display(value, timeZone);
    }

}
