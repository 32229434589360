import {Directive, HostBinding, EventEmitter, Output} from "@angular/core";

@Directive({
  selector: "[appActiveListener]"
})
export class ActiveListener {
  @Output()
  onActive = new EventEmitter<boolean>();

  @HostBinding("class.active")
  public active;

}
