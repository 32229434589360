import { Report } from "@rezonence/core";
import { DownloadRequest } from "./DownloadRequest";
import FileSaver from 'file-saver';
import { Column, sum, ReportDataFormatter } from "../data";
import { EventType } from "@rezonence/sdk";
import { unparse } from "papaparse";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class ReportDataDownloader {

    constructor(private formatter: ReportDataFormatter) {
    }

    toFileName(report: Report, timeZone: string): string {
        const currentDate = this.formatter.formatDate({ date: new Date().toISOString(), timeZone });
        return `${report.title} report ${currentDate}.csv`;
    }

    toCsvString(request: DownloadRequest, columns: Column[]): string {
      const sortedRows = [...request.rows].sort((r1, r2) => r1.startdate.localeCompare(r2.startdate));
        const formattedRows = sortedRows.map(row => this.formatter.parseRow(row, request.timeZone))
            .map(row => columns.reduce((formattedRow, col) => ({
                ...formattedRow,
                [col]: row[col]
            }), {}));
        return unparse(formattedRows, {
            header: true
        })
    }

    toColumns(request: DownloadRequest): Column[] {
        const columns = [Column.Date, Column.TimeZone, Column.ViewableImpressions, Column.Engagements];

        // Then calculate the rates
        if (sum(request.rows, EventType.VideoStarted)) {
            columns.push(Column.VideoStarts, Column.VideoStartRate, Column.VideoCompletions, Column.VideoCompletionRate);
        }

        if (request.report.displayClicks) {
            columns.push(Column.Clicks, Column.ClickThroughRate);
        }
        return columns;
    }

    async download(request: DownloadRequest): Promise<void> {
        const columns = this.toColumns(request);
        const stringToWrite = this.toCsvString(request, columns);
        const blob = new Blob([stringToWrite],
            {
                type: "text/csv;charset=utf-8;"
            }
        );
        FileSaver.saveAs(blob, this.toFileName(request.report, request.timeZone));
    }
}
