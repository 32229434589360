import { Pipe, PipeTransform } from "@angular/core";
import { sleep } from "@rezonence/sdk";

@Pipe({
    name: "delay"
})
export class DelayPipe implements PipeTransform {
    async transform<T>(value: T, period: number): Promise<T> {
        await sleep(period);
        return value;
    }

}