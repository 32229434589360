import { Component, ViewEncapsulation, Input, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { Column, EventCountRow, ReportDataFormatter } from "../data";
import { Report } from "@rezonence/core";
import { EventCountRecord } from "@rezonence/analytics-dao";
import { EventType } from "@rezonence/sdk";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { Subscription, ReplaySubject, Subject, combineLatest } from "rxjs";
import { MatTableDataSource } from "@angular/material/table";
import { FreewallEventColumn } from "@rezonence/freewall-events";

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: "app-report-data",
  templateUrl: "./ReportTable.html",
  styleUrls: ["./ReportTable.css"]
})
export class ReportTable implements OnInit, OnDestroy {

  @Input()
  report: Report;

  timeZone$: Subject<string> = new ReplaySubject(1);
  eventCounts$: Subject<EventCountRecord<FreewallEventColumn>[]> = new ReplaySubject(1);
  dataSource: MatTableDataSource<EventCountRow> = new MatTableDataSource();

  // eslint-disable-next-line @typescript-eslint/naming-convention
  EventType = EventType;

  showVideoColumns: boolean = false;

  subscription: Subscription;

  constructor(public readonly formatter: ReportDataFormatter) {
  }

  @Input()
  set timeZone(input: string) {
    this.timeZone$.next(input);
  }

  @Input()
  set eventCounts(input: EventCountRecord<FreewallEventColumn>[]) {
    this.eventCounts$.next(input);
  }

  @ViewChild(MatSort)
  set sort(input: MatSort) {
    this.dataSource.sort = input;
  }

  @ViewChild(MatPaginator)
  set paginator(input: MatPaginator) {
    this.dataSource.paginator = input
  }

  toggleVideoColumns() {
    this.showVideoColumns = !this.showVideoColumns;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = combineLatest([this.eventCounts$, this.timeZone$]).subscribe(([eventCounts, timeZone]) => {
      this.dataSource.data = eventCounts.map(r => this.formatter.toEventCountRow(r, timeZone));
    });
  }

}
