import { Pipe, PipeTransform } from "@angular/core";
import { EventCountRecord } from "@rezonence/analytics-dao";
import { Report } from "@rezonence/core";
import { Column, sum } from "../data";
import { EventType } from "@rezonence/sdk";
import { FreewallEventColumn } from "@rezonence/freewall-events";

@Pipe({
    name: "selectColumns"
})
export class ColumnSelectorPipe implements PipeTransform {

    readonly baseColumns = [
        Column.Date,
        Column.Engagements
    ];

    readonly clickColumns = [
        Column.Clicks,
        Column.ClickThroughRate
    ];

    readonly videoColumns = [
        Column.VideoStarts,
        Column.VideoCompletions,
        Column.VideoCompletionRate
    ];

  displayVideoColumns(eventCounts: EventCountRecord<FreewallEventColumn>[], showVideoColumns: boolean): boolean {
        const videoStarts = sum(eventCounts, EventType.VideoStarted);
        return showVideoColumns && videoStarts > 0;
    }

  transform(eventCounts: EventCountRecord<FreewallEventColumn>[], report: Report, showVideoColumns: boolean): Column[] {
        const displayVideoColumns = this.displayVideoColumns(eventCounts, showVideoColumns);
        const extraColumns = report.displayClicks ?  displayVideoColumns ? this.videoColumns : this.clickColumns : [];
        return [
            ...this.baseColumns,
            ...extraColumns
        ];
    }

}
