
import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from "@angular/core";
import { TickDisplayType } from "./TickDisplayType";
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, Subscription } from "rxjs";
import { AxisOrientation } from "./AxisOrientation";
import { AxisConfig } from "./AxisConfig";
import d3 from "d3";

@Directive({
  selector: "appProgressAxis, [appProgressAxis]"
})
export class ProgressBarAxis implements OnInit, OnDestroy {


  @Input() public tick: number;

  root;
  axis;
  axisData;
  renderSubscription: Subscription;

  orientationSubject = new ReplaySubject<AxisOrientation>(1);
  displayTypeSubject = new ReplaySubject<TickDisplayType>(1);
  maxSubject = new ReplaySubject<number | Date>(1);
  minSubject = new ReplaySubject<number | Date>(1);
  heightSubject = new ReplaySubject<number>(1);
  resizeSubject = new BehaviorSubject<Event>(null);

  config$: Observable<AxisConfig> = combineLatest([
    this.orientationSubject,
    this.displayTypeSubject,
    this.maxSubject,
    this.heightSubject,
    this.minSubject,
  ]).pipe(map(([orientation, displayType, max, height, min]) => ({ orientation, displayType, max, height, min })));

  constructor(private elementRef: ElementRef) {
  }

  @Input() set min(min: number | Date) {
    this.minSubject.next(min);
  }

  @Input() set max(max: number | Date) {
    this.maxSubject.next(max);
  }

  @Input() set height(height: number) {
    this.heightSubject.next(height);
  }

  @Input() set displayType(displayType: TickDisplayType) {
    this.displayTypeSubject.next(displayType);
  }

  @Input() set orientation(orientation: AxisOrientation) {
    this.orientationSubject.next(orientation);
  }

  @HostListener("window:resize", ['$event'])
  onResize(event) {
    this.resizeSubject.next(event);
  }

  ngOnInit() {
    this.renderSubscription = combineLatest([this.config$, this.resizeSubject]).subscribe(([config]) => this.render(config));
  }

  render(config: AxisConfig): void {
    const el: HTMLElement = this.elementRef.nativeElement;
    this.root = d3.select(el);
    const width = el.offsetWidth;
    const domain = [config.min, config.max] as number[];
    const scale = config.displayType === TickDisplayType.Time
      ? d3.time.scale().domain(domain).range([0, width])
      : d3.scale.linear().domain(domain).range([0, width]);
    this.axisData = d3.svg.axis().scale(scale).orient(config.orientation);
    this.formatTicks(config, width);
    if (!this.axis) {
      this.drawAxis(config, width);
    } else {
      this.root.select(".axis").call(this.axisData);
      this.root.select("line.extra-tick")
        .attr("transform", `translate(${width * this.tick},0)`);
    }
    this.drawTicks(width);
  }

  ngOnDestroy() {
    this.renderSubscription.unsubscribe();
  }

  renderTick(ticks: SVGGraphicsElement[], width: number, tick: SVGGraphicsElement, index: number): void {
    const x = d3.transform(d3.select(tick).attr("transform")).translate[0];
    const boundingBox = tick.getBBox();
    const next = ticks[index + 1];
    if (x - (boundingBox.width / 2) < 0 || (x + (boundingBox.width / 2) > width)) {
      d3.select(tick).attr("visibility", "hidden");
    }
    if (next) {
      this.hideOverlap(tick, next);
    }
  }

  hideOverlap(tick: SVGGraphicsElement, next: SVGGraphicsElement) {
    const tickRect = tick.getBoundingClientRect();
    const nextRect = (next as SVGGraphicsElement).getBoundingClientRect();
    if (tickRect && nextRect && (tickRect.right > nextRect.left)) {
      d3.select(next).attr("visibility", "hidden");
    }
  }

  private drawTicks(width: number) {
    const ticks = this.axis.selectAll(".tick")[0];
    d3.selectAll(ticks).attr("visibility", "visible");
    // Hide any ticks that extend beyond the edges
    ticks.forEach((tick: SVGGraphicsElement, index) => this.renderTick(ticks, width, tick, index));
  }

  private drawAxis(config: AxisConfig, width: number) {
    this.root.classed("progress-axis", true);
    this.axis = this.root
      .append("svg")
      .style({
        width: "100%",
        height: config.height ? `${config.height}px` : ""
      })
      .append("g")
      .attr("class", "axis")
      .style({
        width: "100%",
        height: config.height ? `${config.height}px` : "",
      }).call(this.axisData);
    if (config.orientation === AxisOrientation.Top) {
      this.root.select("g.axis").attr("transform", `translate(0,${config.height})`);
    }
    if (this.tick) {
      this.addSpecifiedTick(config, width);
    }
  }

  private formatTicks(config: AxisConfig, width: number) {
    this.axisData = config.displayType === TickDisplayType.Percent
      ? this.axisData.tickFormat(d3.format(".0%"))
      : this.axisData.tickFormat(this.getTimeTickFormat());
    this.setNumberOfTicks(width);
  }

  private getTimeTickFormat() {
    return d3.time.format.multi([
      [".%L", (d) => d.getMilliseconds()],
      [":%S", (d) => d.getSeconds()],
      ["%H:%M", (d) => d.getMinutes()],
      ["%H:%M", (d) => d.getHours()],
      ["%b %d", d => d.getDate() && d.getDate() !== 1],
      ["%B", d => d.getMonth()],
      ["%Y", () => true]
    ]);
  }

  private setNumberOfTicks(width: number) {
    const numberOfTicks = Math.min(Math.floor(width / 100), 30);
    this.axisData = this.axisData.ticks(numberOfTicks);
  }

  private addSpecifiedTick(config: AxisConfig, width: number) {
    const borderWidth = 2;
    const x2 = 0;
    const y1 = borderWidth * -1;
    const y2 = (config.height * -1) + borderWidth;
    this.root.select("g.axis").insert("line", ".tick")
      .attr("y1", y1)
      .attr("x2", x2)
      .attr("y2", y2)
      .attr("class", "extra-tick")
      .attr("stroke-width", 2)
      .attr("stroke", "red")
      .attr("transform", `translate(${width * this.tick},0)`);
  }
}
