import { EventCountRecord } from "@rezonence/analytics-dao";
import { Column } from "./Column";
import { formatInTimeZone, toDate } from 'date-fns-tz'
import { DatePipe, DecimalPipe, PercentPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { EventCountRow } from "./EventCountRow";
import { OutputType } from "./OutputType";
import { passThrough } from "./passThrough";
import { FreewallEventColumn } from "@rezonence/freewall-events";

@Injectable({
  providedIn: "root"
})
export class ReportDataFormatter {

  readonly decimalFormat = "1.2-2";

  countTransformer: Record<OutputType, (input: number, timeZone: string) => string> = {
    download: v => `${v}`,
    display: v => this.decimalPipe.transform(v)
  }

  percentTransformer: Record<OutputType, (input: number, timeZone: string) => string> = {
    download: v => this.formatPercent(v),
    display: v => this.formatPercent(v)
  }

  readonly formatters: { [K in keyof EventCountRow]: Record<OutputType, (value: EventCountRow[K], timeZone: string) => string> } = {
    [Column.Date]: {
      display: (date, timeZone) => this.datePipe.transform(toDate(date, { timeZone }), 'mediumDate', timeZone),
      download: passThrough
    },
    [Column.TimeZone]: {
      display: passThrough,
      download: passThrough
    },
    [Column.ViewableImpressions]: this.countTransformer,
    [Column.Engagements]: this.countTransformer,
    [Column.Clicks]: this.countTransformer,
    [Column.VideoStarts]: this.countTransformer,
    [Column.VideoCompletions]: this.countTransformer,
    [Column.VideoCompletionRate]: this.percentTransformer,
    [Column.ClickThroughRate]: this.percentTransformer,
    [Column.EngagementRate]: this.percentTransformer,
    [Column.VideoStartRate]: this.percentTransformer
  };

  constructor(private percentPipe: PercentPipe, private datePipe: DatePipe, private decimalPipe: DecimalPipe) {
  }

  toEventCountRow(row: EventCountRecord<FreewallEventColumn>, timeZone: string): EventCountRow {
    const totalClicks = this.totalClicks(row);
    return {
      [Column.Date]: row.startdate,
      [Column.TimeZone]: timeZone,
      [Column.ViewableImpressions]: row.invw,
      [Column.Engagements]: row.unlk,
      [Column.EngagementRate]: row.unlk / row.invw,
      [Column.Clicks]: totalClicks,
      [Column.ClickThroughRate]: totalClicks / row.unlk,
      [Column.VideoStarts]: row.vst,
      [Column.VideoCompletions]: row.ven,
      [Column.VideoCompletionRate]: row.ven / row.vst,
      [Column.VideoStartRate]: row.vst / row.invw
    };
  }

  parseRow(record: EventCountRecord<FreewallEventColumn>, timeZone: string): Record<Column, string> {
    const row = this.toEventCountRow(record, timeZone);
    return Object.entries(row).reduce((record, [column, value]) => ({
      ...record,
      [column]: this.formatters[column].download(value, timeZone)
    }), {} as Record<Column, string>);
  }

  totalClicks(row: EventCountRecord<FreewallEventColumn>): number {
    return row.clk + row.iclk + row.rclk + row.kclk + row.cimg;
  }

  formatPercent(input: number): string {
    return this.percentPipe.transform(input, this.decimalFormat)
  }

  formatDate(request: { date: string, timeZone: string }): string {
    return formatInTimeZone(new Date(request.date), request.timeZone, 'yyyy-MM-dd');
  }
}
