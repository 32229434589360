import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { CDN, ElementId, Report } from "@rezonence/core";
import { race } from "@rezonence/freewall-tag";
import { RezonenceGlobal } from "@rezonence/freewall-loader";
import { rezonenceGlobalVar, sleep } from "@rezonence/sdk";
import { ButtonSelector } from "@rezonence/freewall-buttons";
import { reportConfigId } from "@rezonence/live-report-config";
import { ReplaySubject, Subject, Subscription } from "rxjs";
import { AnswerCountRecord } from "@rezonence/analytics-dao";

@Component({
  selector: "demo-banner",
  template: `<div id="${ElementId.banner}" [style.pointer-events]="'none'"></div>
    <ng-container *ngIf="(report$ | async).answerBreakdown">
        <ng-container *ngFor="let answerButton of answerButtons; let buttonIndex = index">
            <answer-share [button]="answerButton" [answerCounts]="answerCounts$ | async" [questionIndex]="questionIndex$ | async" [adId]="adId$ | async" *ngIf="answerButton.offsetParent">
            </answer-share>
        </ng-container>
    </ng-container>`

})
export class DemoBanner implements OnInit, OnDestroy {
  subscription: Subscription;
  adId$: Subject<string> = new ReplaySubject(1);
  questionIndex$: Subject<number> = new ReplaySubject(1);
  answerCounts$: Subject<AnswerCountRecord[]> = new ReplaySubject(1);
  report$: Subject<Report> = new ReplaySubject(1);


  constructor() {
  }

  @Input()
  context: Window;


  get rezonenceGlobal(): RezonenceGlobal {
    return this.context[rezonenceGlobalVar];
  }

  get answerButtons(): HTMLDivElement[] {
    return Array.from(this.context.document.querySelectorAll(`.${ButtonSelector.Button}`));
  }

  @Input()
  set adId(input: string) {
    this.adId$.next(input);
  }

  @Input()
  set questionIndex(input: number) {
    this.questionIndex$.next(input);
  }

  @Input()
  set answerCounts(input: AnswerCountRecord[]) {
    this.answerCounts$.next(input);
  }

  @Input()
  set report(input: Report) {
    this.report$.next(input);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.adId$.subscribe(adId => this.renderFreeWall(adId));
  }

  async renderFreeWall(adId: string): Promise<HTMLDivElement> {
    this.context[rezonenceGlobalVar] = this.createRezonenceGlobal(reportConfigId);
    await race([CDN.CDN3], {
      aid: adId,
      cid: reportConfigId,
      test: true,
      nid: undefined,
      top: false,
      app: false
    }, this.context);
    return this.context.document.getElementById(ElementId.banner) as HTMLDivElement;
  }

  async resolveLoadedQuestionIndex(): Promise<number> {
    while (!this.rezonenceGlobal?.prg?.sel) {
      await sleep(100);
    }
    return this.rezonenceGlobal.prg.sel.qnm;
  }

  createRezonenceGlobal(configId: string): RezonenceGlobal {
    const emptyInstruction = "<div></div>";
    const defaultInstructions = {
      topHtml: emptyInstruction,
      bottomHtml: emptyInstruction
    };
    return {
      cid: configId,
      pub: {
        disableKicker: true,
        selectors: [],
        htm: {
          instructions: {
            qa: defaultInstructions,
            video: defaultInstructions
          }
        },
        fns: {
          ins: () => { },
          fwp: () => { }
        },
        cap: {}
      }
    } as RezonenceGlobal;
  }
}
