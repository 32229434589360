import {Component, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import { HelpTopic } from "./HelpTopic";

@Component({
  selector: "app-help",
  templateUrl: "./HelpComponent.html",
  styleUrls: ["./HelpComponent.css"]
})
export class HelpComponent {

  readonly helpTitles: Record<HelpTopic, string> = {
    [HelpTopic.Engagement]: "Engagements",
    [HelpTopic.EngagementRate]: "Engagement rate (ER)",
    [HelpTopic.ClickThroughRate]: "Click-through rate (CTR)",
    [HelpTopic.Click]: "Clicks",
    [HelpTopic.Cost]: "Costs",
    [HelpTopic.Impression]: "Impressions",
    [HelpTopic.VideoCompletion]: "Video completions",
    [HelpTopic.VideoCompletionRate]: "Video completion rate (VCR)",
    [HelpTopic.VideoStart]: "Video starts",
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly HelpTopic = HelpTopic;
  topic: HelpTopic;
  title: string;

  constructor(public ref: MatDialogRef<HelpComponent>) { }

}
