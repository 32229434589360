import {NgModule} from "@angular/core";
import {DataModule} from "../data/DataModule";
import {FormsModule} from "@angular/forms";
import {BrowserModule} from "@angular/platform-browser";
import {ReportSummary} from "./ReportSummary";
import {HelpButton, HelpComponent} from "./help";
import {HttpClientModule} from "@angular/common/http";
import { DaysRemainingPipe } from "./DaysRemainingPipe";

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DataModule
  ],
  declarations: [
    ReportSummary,
    HelpComponent,
    DaysRemainingPipe,
    HelpButton,
    HelpComponent
  ],
  exports: [
    ReportSummary
  ]
})
export class SummaryModule {

}
