import { Pipe, PipeTransform } from "@angular/core";
import { FreeWallConfig, Optional } from "@rezonence/sdk";
import { ConfigResolverService } from "./ConfigResolverService";

@Pipe({
  name: "freewallConfig"
})
export class FreeWallConfigPipe implements PipeTransform {
  constructor(private configResolver: ConfigResolverService) {
  }

  transform(adId: string): Promise<Optional<FreeWallConfig>> {
    return this.configResolver.fromAdId(adId);
  }

}
