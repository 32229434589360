import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { App } from "./App";
import { BannerModule } from "./banner";
import { ChartModule } from "./chart/ChartModule";
import { DataModule } from "./data";
import { SummaryModule } from "./summary/SummaryModule";
import { TableModule } from "./table";
import { TitleModule } from "./title";
import { DatePipe, DecimalPipe, PercentPipe } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpClientModule } from "@angular/common/http";
import { MatDialogModule } from "@angular/material/dialog";
import { RouterModule } from "@angular/router";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner"

/*
 * Platform and Environment providers/directives/pipes
 */
// AppComponent is our top level component

// Application wide providers
const providers = [
  DatePipe,
  PercentPipe,
  DecimalPipe
];

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [App],
  declarations: [
    App
  ],
  imports: [ // import Angular's modules
    RouterModule.forRoot([]),
    MatDialogModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    BannerModule,
    ChartModule,
    DataModule,
    SummaryModule,
    TableModule,
    TitleModule,
    BrowserAnimationsModule,
  ],
  providers
})
export class AppModule {
}
