import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map } from 'rxjs/operators';
import { reportIdQueryParam, showImpressionsQueryParam} from "@rezonence/live-report-config";
import { ReportConfig } from "./ReportConfig";

@Injectable({
    providedIn: "root"
})
export class ReportConfigResolver {
    public readonly reportConfig$: Observable<ReportConfig>;
    constructor(route: ActivatedRoute) {
        this.reportConfig$ = route.queryParams
        .pipe(map(p => ({reportId: p[reportIdQueryParam], showImpressions: p[showImpressionsQueryParam] === "true"})))
        .pipe(filter(config => !!config.reportId));
    }

}
