import {Pipe, PipeTransform} from "@angular/core";
import { sum } from "./sum";

@Pipe({
    name: "sum"
})
export class SumPipe implements PipeTransform {
    
    transform<T extends string>(rows: Record<T, number>[], ...columns: T[]): number {
        return sum<T>(rows, ...columns);
    }

}