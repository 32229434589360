import { NgModule } from "@angular/core";
import { DataModule } from "../data";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { TopBar } from "./TopBar";
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    DataModule
  ],
  declarations: [
    TopBar
  ],
  exports: [
    TopBar
  ]
})
export class TitleModule {

}
