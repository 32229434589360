import { Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ReportConfig, ReportDataFormatter } from "../data";
import { EventType } from "@rezonence/sdk";
import { Report } from "@rezonence/core";
import { EventCountRecord } from "@rezonence/analytics-dao";
import { HelpTopic, HelpComponent } from "./help";
import { FreewallEventColumn } from "@rezonence/freewall-events";

@Component({
  selector: "app-report-summary",
  templateUrl: "./ReportSummary.html",
  styleUrls: ["./ReportSummary.css"]
})
export class ReportSummary {

  @Input()
  rows: EventCountRecord<FreewallEventColumn>[];

  @Input()
  reportConfig: ReportConfig;

  @Input()
  report: Report;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly EventType = EventType;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  readonly HelpTopic = HelpTopic;

  readonly decimalFormat: string;
  readonly dateFormat = "mediumDate";

  constructor(private dialog: MatDialog, formatter: ReportDataFormatter) {
    this.decimalFormat = formatter.decimalFormat;
  }

  public displayInfo(topic: HelpTopic) {
    const helpComponent = this.dialog.open(HelpComponent);
    helpComponent.componentInstance.topic = topic;
  }
}
