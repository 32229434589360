<h3>{{helpTitles[topic]}}</h3>
<div [ngSwitch]="topic" class="help-body">
    <ng-container *ngSwitchCase="HelpTopic.EngagementRate">
        <pre>Engagements / Viewable impressions</pre>
    </ng-container>
    <ng-container *ngSwitchCase="HelpTopic.Engagement">
        <p>An engagement event is recorded when a user unlocks an article by correctly answering the question.<br><br>
        </p>
        <p><strong>Engagement rate (ER)</strong></p>
        <pre>Engagements / Viewable impressions</pre>
    </ng-container>
    <ng-container *ngSwitchCase="HelpTopic.Impression">
        <p>A viewable impression is registered when an ad is loaded on the page and in view.</p>
    </ng-container>
    <ng-container *ngSwitchCase="HelpTopic.Click">
        <p>A click event is registered when a user clicks on one of the static images and is directed to a landing
            site.<br><br></p>
        <p><strong>Click-through rate (CTR)</strong></p>
        <pre>Clicks / Engagements</pre>
    </ng-container>
    <ng-container *ngSwitchCase="HelpTopic.VideoCompletion">
        <p>A video completion event is recorded when a video plays through to the end. Videos will auto-pause when not
            100% in view.<br><br></p>
        <p><strong>Video Completion Rate (VCR)</strong></p>
        <pre>Video completions / Video starts</pre>
    </ng-container>
    <ng-container *ngSwitchCase="HelpTopic.VideoStart">
        <p>A video start event is recorded when a video begins to play after the user clicks the play button.<br><br>
        </p>
        <p><strong>Video Start Rate (VSR)</strong></p>
        <pre>Video Starts / Viewable impressions</pre>
    </ng-container>
    <ng-container *ngSwitchCase="HelpTopic.VideoCompletionRate">
        <pre>Video completions / Video starts</pre>
    </ng-container>
    <ng-container *ngSwitchCase="HelpTopic.ClickThroughRate">
        <pre>Clicks / Engagements</pre>
    </ng-container>
    <ng-container *ngSwitchCase="HelpTopic.Cost">
        <p><strong>Cost per engagement (CPE)</strong></p>
        <p>The gross earnings per correct answer clicked</p>
        <p><strong>Effective CPM (eCPM)</strong></p>
        <p>The average gross earnings from a thousand viewable impressions</p>
        <pre>Cost per engagement x Engagement rate x 1000</pre>
    </ng-container>
</div>