import { Injectable } from "@angular/core";
import { combineLatest, map, Observable } from "rxjs";
import { Column } from "./Column";
import { EventCountResolver } from "./EventCountResolver";
import { ReportDataFormatter } from "./ReportDataFormatter";
import { TimeZoneService } from "./TimeZoneService";

@Injectable({
    providedIn: "root"
})
export class FormattedDataResolver {
    formattedRows$: Observable<Record<Column, string>[]>;
    constructor(dataSource: EventCountResolver, timeZoneService: TimeZoneService, formatter: ReportDataFormatter) {
        this.formattedRows$ = combineLatest([dataSource.rows$, timeZoneService.timeZone$])
            .pipe(map(([rows, timeZone]) => rows.map(row => formatter.parseRow(row, timeZone))));
    }
}