import { NgModule } from "@angular/core";
import { DataModule } from "../data";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { ReportTable } from "./ReportTable";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { ButtonsModule } from "ngx-bootstrap/buttons";
import { MatTableModule } from "@angular/material/table";
import { MatButtonModule } from "@angular/material/button";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { ColumnSelectorPipe } from "./ColumnSelectorPipe";
import { DisplayValuePipe } from "./DisplayValuePipe";
import { DownloadButton } from "./DownloadButton";

@NgModule({
  imports: [
    DataModule,
    FormsModule,
    BrowserModule,
    PaginationModule.forRoot(),
    ButtonsModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
  ],
  declarations: [
    DownloadButton,
    ReportTable,
    ColumnSelectorPipe,
    DisplayValuePipe
  ],
  exports: [
    ReportTable
  ]
})
export class TableModule {

}
