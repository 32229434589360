<div *ngIf="report && counts && report.targetEngagements" class="report-progress">
     <ng-container *ngIf="{engagements: counts | sum:EventType.FreeWallUnlocked} as engagementData">
          <div appProgressAxis [min]="0.0" [max]="1.0" [height]="90" [displayType]="TickDisplayType.Percent"
               [orientation]="AxisOrientation.Bottom"></div>
          <div appProgressAxis [min]="report.startDate | toDate" [max]="report.endDate | toDate" [height]="90"
               [displayType]="TickDisplayType.Time" [orientation]="AxisOrientation.Top"></div>
               <progressbar [type]="'success'" [animate]="true" [value]="engagementData.engagements / (report.targetEngagements + report.adjustment) | delay : 1000 | async" [max]="1.0">
               </progressbar>
               <progressbar [type]="engagementData.engagements < report.targetEngagements ? 'info' : 'success'" [animate]="true" [value]="report | timeProgress : (report | currentDate) | delay : 1000 | async" [max]="1.0">
               </progressbar>
     </ng-container>
</div>