import { Pipe, PipeTransform } from "@angular/core";
import { AnswerCountRecord } from "@rezonence/analytics-dao";
import { uniqueArrayItem } from "@rezonence/array-utils";
import { Report } from "@rezonence/core";

@Pipe({
  name: "adIds"
})
export class AdIdsPipe implements PipeTransform {

  transform(report: Report, answerCounts: AnswerCountRecord[]) {
    return [...report.adIds, ...answerCounts.map(r => r.aid)].filter(uniqueArrayItem)
  }

}
