export enum HelpTopic {
    EngagementRate = "er",
    Engagement = "engagement",
    Impression = "impression",
    Click = "click",
    VideoCompletion = "videoCompletion",
    VideoStart = "videoStart",
    VideoCompletionRate = "vcr",
    ClickThroughRate = "ctr",
    Cost = "cost"
}
